import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
    TextField,
    InputAdornment, Box,
} from "@mui/material";
import {DeleteOutlineOutlined, ShareOutlined, SearchOutlined, CloseOutlined, Add as AddIcon} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

// project import
import store from "../../store/store";
import {
    checkAll,
    setGroupsToShare,
    setSearchLabelsFilter,
} from "../../store/reducers/groupsSlice";
import {
    openShareLabelsDialog,
    openAddNewLabelDialog,
    openAlertDeleteLabelDialog
} from "../../store/reducers/dialogSlice";
import LabelsPanelFilter from "./LabelsPanelFilter";

export function LabelsPanel({drawerWidth}) {
    const isCheckedLabels = useSelector((state) => state.groups.isCheckedLabels);
    const isAllCheckedLabels = useSelector((state) => state.groups.isAllCheckedLabels);
    const checkedLabels = useSelector((state) => state.groups.checkedLabels);
    const searchLabelsFilter = useSelector((state) => state.groups.searchLabelsFilter);
    const countSelectedGroups = checkedLabels.length;
    const handleCheckAllLabels = (event) => {
        store.dispatch(checkAll(event.target.checked));
    };

    const handleDeleteGroups = () => {
        store.dispatch(openAlertDeleteLabelDialog(true));
    };

    const handleShareLabels = () => {
        store.dispatch(setGroupsToShare({groups: checkedLabels}));
        store.dispatch(openShareLabelsDialog(true));
    };
    const handleClickOpenAddNewLabelDialog = () => {
        store.dispatch(openAddNewLabelDialog(true));
    };
    return (
        <List
            disablePadding
            sx={{
                marginTop: '8px',
            }}>
            {
                isCheckedLabels ?
                    <ListItem key={'labels_panel_checked_labels'}
                              disablePadding
                              disableGutters
                              sx={{
                                  height: '40px',
                              }}
                              secondaryAction={
                                  <>
                                      <Button variant="contained"
                                              startIcon={
                                                  <ShareOutlined sx={{
                                                      width: '18px',
                                                      height: '18px',
                                                  }}/>
                                              }
                                              sx={{
                                                  maxWidth: '160px',
                                                  height: '30px',
                                                  marginRight: '3px',
                                                  background: "rgba(59, 125, 237, 1)",
                                              }}
                                              onClick={handleShareLabels}
                                      >
                                          <Box
                                              sx={{
                                                  width: 'auto',
                                                  maxWidth: '100px',
                                              }}>
                                              <Typography
                                                  noWrap
                                                  sx={{
                                                      color: '#FFF',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: '13px',
                                                      fontStyle: 'normal',
                                                      fontWeight: '600',
                                                      lineHeight: '22px',
                                                  }}
                                              >Share ({countSelectedGroups})</Typography>
                                          </Box>
                                      </Button>
                                      <Tooltip title='Your contacts will not be deleted. They will be available in All Contacts.  If you share this label with users, the label and its contacts will be deleted on their end.' placement="bottom">
                                          <IconButton
                                              onClick={handleDeleteGroups}
                                          >
                                              <DeleteOutlineOutlined/>
                                          </IconButton>
                                      </Tooltip>
                                  </>
                              }
                    >
                        <Tooltip
                            title={isCheckedLabels && !isAllCheckedLabels ? 'Deselect labels' : isAllCheckedLabels ? 'Deselect all labels' : 'Select all labels'}
                            placement="bottom">
                            <IconButton
                                sx={{
                                    marginLeft: '14px',
                                }}
                            >
                                <Checkbox
                                    inputProps={{'aria-label': 'Check all labels'}}
                                    indeterminate={isAllCheckedLabels === false}
                                    checked={isAllCheckedLabels === true}
                                    onChange={handleCheckAllLabels}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                        color: 'rgba(59, 125, 237, 1)',
                                    }}/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    :
                    <ListItem key={'labels_panel'}
                              disablePadding
                              sx={{
                                  height: '40px',
                                  maxWidth: drawerWidth,
                              }}
                              secondaryAction={
                                  <>
                                      <Tooltip title='Create a new label' placement="bottom">
                                          <IconButton onClick={handleClickOpenAddNewLabelDialog}
                                                      aria-label='settings'
                                                      color='default'
                                                      size="medium"
                                                      sx={{
                                                          marginRight: '-6px'
                                                      }}
                                          >
                                              <AddIcon
                                                  sx={{
                                                      width: 24,
                                                      flexShrink: 0,
                                                  }}
                                              />
                                          </IconButton>
                                      </Tooltip>
                                      <LabelsPanelFilter/>
                                  </>
                              }
                    >
                        <Tooltip title='Select all labels' placement="bottom">
                            <IconButton
                                sx={{
                                    marginLeft: '14px',
                                    marginRight: '8px',
                                }}
                            >
                                <Checkbox
                                    inputProps={{'aria-label': 'Check all labels'}}
                                    color="default"
                                    onChange={handleCheckAllLabels}
                                    checked={false}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                    }}/>
                            </IconButton>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography variant={'sideBarNumbers'} color={'rgba(32, 33, 36, 0.54)'}>
                                    Labels
                                </Typography>
                            }
                        />
                    </ListItem>
            }
            <ListItem key={'labels_search'}
                      disablePadding
                      sx={{
                          marginTop: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                      }}>
                <StyledTextField
                    // sx={{marginLeft: '18px'}}
                    onChange={(event) => {
                        store.dispatch(setSearchLabelsFilter(event.target.value));
                    }}
                    value={searchLabelsFilter}
                    size="small"
                    id="input-with-icon-textfield"
                    placeholder="Search labels"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{marginLeft: '-3px',}}>
                                <SearchOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            searchLabelsFilter && <InputAdornment position="end">
                                <CloseOutlined
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        store.dispatch(setSearchLabelsFilter(''));
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            height: '36px',
                            width: '220px',
                            color: 'rgba(32, 33, 36, 1)',
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '-0.28px',
                        }
                    }}
                />
            </ListItem>
        </List>
    )
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    backgroundColor: '#F1F3F4',
    '.MuiInput-root': {
        height: '40px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F1F3F4',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: '#F1F3F4',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F1F3F4',
        },
    },
}));
