import * as React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton, Avatar, Chip, Stack, InputAdornment, TextField,
} from "@mui/material";
import {CloseOutlined, SearchOutlined} from '@mui/icons-material';
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import store from "../../store/store";
import {openAddExistingContactsDialog} from "../../store/reducers/dialogSlice";
import {selectGroups, addExistingContactsToGroup} from "../../store/reducers/groupsSlice";
import {addContactsToGroup} from "../../store/reducers/contactsSlice";
import {StyledDataGrid} from "./StyledDataGrid";

export default function AddExistingContactsDialog() {
    const open = useSelector((state) => state.dialogsOpen.addExistingContacts.value);
    const contacts = useSelector((state) => state.contacts.contacts);
    const groups = useSelector(selectGroups);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const addExistingContactsToGroupStatus = useSelector((state) => state.groups.addExistingContactsToGroupStatus);
    const loading = addExistingContactsToGroupStatus === 'loading';
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const handleClose = () => {
        store.dispatch(openAddExistingContactsDialog(false));
    };
    const handleAddContacts = async () => {
        const result = await store.dispatch(addExistingContactsToGroup({
            groupResource: currentGroup.resource,
            contactsResources: rowSelectionModel
        }));
        await store.dispatch(addContactsToGroup(result));
        handleClose();
    };
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);
    const [searchUsersFilter, setSearchUsersFilter] = React.useState('');
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderHeader: (params) => (
                params.colDef.headerName
            ),
            valueGetter: (params) => {
                return params.value?.trim() || params.row.email;
            },
            renderCell: params => (
                <>
                    <Box mr={1}><Avatar src={params.row.photo}/></Box>
                    {params.value}
                </>
            )
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            renderHeader: (params) => (params.colDef.headerName),
        },
        {
            field: 'phone',
            headerName: 'Phone number',
            flex: 1,
            renderHeader: (params) => (params.colDef.headerName),
        },
        {
            field: 'organization',
            headerName: 'Job & Organization',
            flex: 1,
            renderHeader: (params) => (params.colDef.headerName),
            renderCell: params => params.row.organization?.name || '',

        },
        {
            field: 'memberShips',
            headerName: 'Labels',
            width: 353.33,
            renderHeader: (params) => (params.colDef.headerName),
            renderCell: (params) => {
                const filteredGroups = [];
                groups.forEach((group) => {
                    const membership = params.row.memberships.find((item) => item.contactGroupMembership?.contactGroupResourceName === group.resource);
                    if (membership) {
                        filteredGroups.push(group);
                    }
                })
                return (
                    <>
                        {
                            filteredGroups.reduce((acc, item, index) => {
                                if (index > 2) return acc;
                                if (index === 2) {
                                    return (
                                        [...acc, <Chip key={index} sx={{marginRight: '8px'}}
                                                       label={<Typography
                                                           variant={'label'}>+{filteredGroups.length - 2}</Typography>}
                                                       size="medium"
                                                       variant={'filled'} color={'default'}/>]
                                    )
                                }
                                return (
                                    [...acc, <Chip key={index}
                                                   sx={{
                                                       marginRight: '8px',
                                                       maxWidth: '200px',
                                                   }}
                                                   label={<Typography noWrap variant={'label'}>{item.name}</Typography>}
                                                   size="medium"
                                                   variant={'filled'} color={'default'}/>]
                                )
                            }, [])
                        }
                    </>
                );
            }
        },
    ];
    const uniqueContacts = contacts.filter((user) => currentGroup ? !currentGroup.contacts_resources.find((item) => user.id === item) : null);
    const rows = getContactsByFilter(uniqueContacts, searchUsersFilter);
    const isButtonDisabled = () => {
        return !rowSelectionModel.length;
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: 1040,
                        maxWidth: 1040,
                        backgroundColor: 'rgba(59, 125, 237, 1)'
                    }
                }}
            >
                <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                    <Stack spacing={0}>
                        <Typography
                            width={900}
                            noWrap
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Add contacts to
                            “{currentGroup && currentGroup.name}”</Typography>
                        <Typography
                            noWrap
                            sx={{
                                color: 'rgba(255, 255, 255, 1)',
                                fontSize: 16,
                                fontFamily: `"Roboto"`,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.2px',
                                marginBottom: 1,
                            }}
                        >
                            Select the contact list you wish these contacts to add
                        </Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: 'rgba(255, 255, 255, 0.54)',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.87)',
                            backgroundColor: 'rgba(0, 0, 0, 0.16)'
                        },
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
                <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Stack spacing={'20px'}>
                        <StyledTextField
                            sx={{
                                width: '100%',
                                height: 42,
                                display: "flex",
                                justifyContent: "center",
                            }}
                            onChange={(event) => {
                                setSearchUsersFilter(event.target.value);
                            }}
                            value={searchUsersFilter}
                            id="input-with-icon-textfield"
                            placeholder="Search contacts"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{marginLeft: '-3px',}}>
                                        <SearchOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    searchUsersFilter && <InputAdornment position="end">
                                        <CloseOutlined
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setSearchUsersFilter('');
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    height: '42px',
                                    width: '100%',
                                    color: 'rgba(32, 33, 36, 1)',
                                    fontSize: '16px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '46px',
                                    letterSpacing: '-0.32px',
                                }
                            }}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                height: '550px',
                            }}
                        >
                            <StyledDataGrid
                                rows={rows}
                                columns={columns}
                                columnHeaderHeight={56}
                                rowHeight={72}
                                autoPageSize
                                checkboxSelection
                                disableColumnMenu
                                disableColumnSelector
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Button onClick={handleClose} variant="text">
                        <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                    </Button>
                    <LoadingButton
                        variant="text"
                        loading={loading}
                        onClick={handleAddContacts}
                        disabled={isButtonDisabled()}
                    >
                        <Typography
                            variant={'dialogButton'}
                            sx={{
                                color: isButtonDisabled() || loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                            }}
                        >
                            Add Contacts
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    backgroundColor: '#F1F3F4',
    '.MuiInput-root': {
        height: '42px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F1F3F4',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: '#F1F3F4',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F1F3F4',
        },
    },
}));

const getContactsByFilter = (contacts, filter) => contacts.filter(contact => contact.name?.toLowerCase().includes(filter && filter.toLowerCase()) || contact.email?.toLowerCase().includes(filter && filter.toLowerCase())
    || contact.phone?.toLowerCase().includes(filter && filter.toLowerCase()));
