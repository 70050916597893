import {useState} from "react";
import {
    Box,
    Checkbox,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    List,
    ListItemSecondaryAction,
    Stack, SvgIcon,
} from "@mui/material";
import {
    ShareOutlined,
    ModeEditOutlineOutlined,
    AddOutlined,
} from "@mui/icons-material";
import * as React from "react";
import {useSelector} from "react-redux";
import Marquee from 'react-double-marquee';

// project import
import store from "../../store/store";
import {setRowSelectionModel} from '../../store/reducers/contactsSlice';
import {
    checkOne,
    changeContacts,
    selectGroups,
    setGroupsToShare,
    setLabelToRename
} from "../../store/reducers/groupsSlice";
import {openAddNewLabelDialog, openRenameLabelDialog, openShareLabelsDialog} from "../../store/reducers/dialogSlice";
import NoLabelsFound from "./NoLabelsFound";
import {checkRole} from "../../services/checkRole";
import * as RolesConstants from "../../constants/roles";

export function Labels({drawerWidth}) {
    const groups = useSelector(selectGroups);
    const labelsFilter = useSelector((state) => state.groups.labelsFilter);
    const searchLabelsFilter = useSelector((state) => state.groups.searchLabelsFilter);
    const groupsFiltered = getLabelsByFilter(groups.reduce((acc, group) => group.resource !== 'contactGroups/myContacts' ? [...acc, group] : acc, []), {
        labelsFilter,
        searchLabelsFilter
    });
    if (!groupsFiltered.length) {
        return <NoLabelsFound/>
    }
    return (
        <List>
            {groupsFiltered.map((group) => (
                <MyListItem key={group.resource} group={group} drawerWidth={drawerWidth}/>))}
            <CreateLabelItem drawerWidth={drawerWidth}/>
        </List>
    )
}

const getLabelsByFilter = (groups, filter) => {
    let filteredGroups;
    switch (filter.labelsFilter.sortBy) {
        case 'last_modified':
            if (filter.labelsFilter.order === 'ASC') {
                groups.sort((a, b) => a.update_time > b.update_time ? 1 : -1)
            } else {
                groups.sort((a, b) => b.update_time > a.update_time ? 1 : -1);
            }
            break;
        case 'alphabetical':
            if (filter.labelsFilter.order === 'ASC') {
                groups.sort((a, b) => a.name > b.name ? 1 : -1);
            } else {
                groups.sort((a, b) => b.name > a.name ? 1 : -1);
            }
            break;
        default:
            if (filter.labelsFilter.order === 'ASC') {
                groups.sort((a, b) => a.update_time > b.update_time ? 1 : -1)
            } else {
                groups.sort((a, b) => b.update_time > a.update_time ? 1 : -1);
            }
    }
    switch (filter.labelsFilter.filter) {
        case 'all':
            filteredGroups = groups;
            break;
        case 'shared':
            filteredGroups = groups.filter(group => group.share.length && group.is_user_owner);
            break;
        case 'not_shared':
            filteredGroups = groups.filter(group => !group.share.length);
            break;
        case 'shared_with_me':
            filteredGroups = groups.filter(group => group.share.length && !group.is_user_owner);
            break;
        default:
            filteredGroups = groups;
    }
    filteredGroups = filteredGroups.filter(item => item.name.toLowerCase().includes(filter.searchLabelsFilter && filter.searchLabelsFilter.toLowerCase()));
    return filteredGroups;
}

const MyListItem = ({group, drawerWidth}) => {
    const [hover, setHover] = useState(false);
    const handleClickLabel = (group) => {
        store.dispatch(changeContacts({resource: group.resource}));
        store.dispatch(setRowSelectionModel([]));
    };
    const handleClickCheckBoxLabel = (event) => {
        event.stopPropagation();
        const status = event.target.checked;
        store.dispatch(checkOne({group: group.resource, status}));
    };
    const groups = useSelector((state) => state.groups.groups);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const checkedLabels = useSelector((state) => state.groups.checkedLabels);
    const isChecked = checkedLabels.some(label => label.group === group.resource);
    const handleShareLabel = (event) => {
        event.stopPropagation();
        store.dispatch(setGroupsToShare({groups: [{group: group.resource}]}));
        store.dispatch(openShareLabelsDialog(true));
    };
    const handleClickOpenRenameLabelDialog = (event, groupId) => {
        event.stopPropagation();
        store.dispatch(setLabelToRename({
            resource: groupId,
            name: groups.find(group => group.resource === groupId).name
        }));
        store.dispatch(openRenameLabelDialog(true));
    };
    return (
        <ListItemButton
            disableRipple
            onClick={() => handleClickLabel(group)}
            key={group.resource}
            sx={{
                maxWidth: drawerWidth,
                bgcolor: currentGroup.resource === group.resource && '#E8F0FE' || isChecked && 'rgba(0, 0, 0, 0.04)',
                borderRadius: '0px 20px 20px 0px',
                height: 40,
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            <ListItemIcon
                sx={{
                    marginRight: -2,
                    marginLeft: '6px',
                }}
            >
                <Checkbox
                    inputProps={{'aria-labelledby': group.id}}
                    id={group.resource}
                    disabled={!group.is_user_owner}
                    checked={isChecked}
                    onClick={handleClickCheckBoxLabel}
                    sx={{
                        display: !(isChecked || hover) && 'none',
                        width: 24,
                        height: 24,
                        color: 'rgba(0, 0, 0, 0.6)',
                    }}
                />
                <LabelIcons group={group} hover={hover}/>
            </ListItemIcon>
            <ListItemText
                sx={{
                    display: !hover && 'none'
                }}
                primary={
                    <div
                        style={{
                            width: '125px',
                            whiteSpace: 'nowrap',
                            color: currentGroup.resource === group.resource && 'rgba(25, 103, 210, 1)',
                            display: !hover && 'none',
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }}
                    >
                        <Marquee
                            delay={0}
                            direction={"left"}
                            children={group.name}
                            scrollWhen={"overflow"}
                        >
                            {group.name}
                        </Marquee>
                    </div>
                }
            />
            <ListItemText
                sx={{
                    display: hover && 'none',
                }}
                primary={
                    <Box
                        sx={{
                            width: '138px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: currentGroup.resource === group.resource && 'rgba(25, 103, 210, 1)',
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                            }}
                            noWrap
                        >
                            {group.name}
                        </Typography>
                    </Box>
                }
            />
            <ListItemSecondaryAction
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: currentGroup.resource === group.resource && 'rgba(25, 103, 210, 1)',
                        display: hover && 'none',
                        marginRight: '2px',
                    }}
                    variant={'sideBarNumbers'}>
                    {group.count}
                </Typography>
                <Stack direction={'row'} spacing={'10px'} sx={{marginRight: '7px',}}>
                    {checkRole(RolesConstants.ROLE_EDIT, group) && <Tooltip
                        placement="bottom"
                        title='Rename label'
                    >
                        <ModeEditOutlineOutlined
                            onClick={(event) => handleClickOpenRenameLabelDialog(event, group.resource)}
                            sx={{
                                width: 18,
                                height: 18,
                                flexShrink: 0,
                                fill: currentGroup.resource !== group.resource && 'rgba(0, 0, 0, 0.32)' || currentGroup.resource === group.resource && '#3B7DED',
                                display: !hover && 'none',
                                '&:hover': {
                                    fill: currentGroup.resource !== group.resource && 'rgba(0, 0, 0, 0.6)' || currentGroup.resource === group.resource && '#1659B6',
                                }
                            }}
                        />
                    </Tooltip>}
                    {checkRole(RolesConstants.ROLE_SHARE, group) && <Tooltip
                        placement="bottom"
                        title='Share label'
                    >
                        <ShareOutlined
                            onClick={handleShareLabel}
                            sx={{
                                width: 18,
                                height: 18,
                                flexShrink: 0,
                                fill: currentGroup.resource !== group.resource && 'rgba(0, 0, 0, 0.32)' || currentGroup.resource === group.resource && '#3B7DED',
                                display: !hover && 'none',
                                '&:hover': {
                                    fill: currentGroup.resource !== group.resource && 'rgba(0, 0, 0, 0.6)' || currentGroup.resource === group.resource && '#1659B6',
                                }
                            }}
                        />
                    </Tooltip>}
                </Stack>
            </ListItemSecondaryAction>
        </ListItemButton>
    )
}

const LabelIcons = ({group, hover}) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const checkedLabels = useSelector((state) => state.groups.checkedLabels);
    const isChecked = checkedLabels.some(label => label.group === group.resource);
    if (group.share.length > 0) {
        if (group.is_user_owner) {
            return (
                <SvgIcon sx={{
                    display: (isChecked || hover) && 'none',
                    width: 20,
                    height: 20,
                    flexShrink: 0,
                    marginLeft: '2px',
                }}
                >
                    {currentGroup.resource === group.resource ?
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM4 14C4.56 11.33 6.11 8.67 10 8.13V6L14 9.73L10 13.47V11.28C7.22 11.28 5.39 12.13 4 14Z"
                                  fill="#1967D2"/>
                        </svg> :
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM4 14C4.56 11.33 6.11 8.67 10 8.13V6L14 9.73L10 13.47V11.28C7.22 11.28 5.39 12.13 4 14Z"
                                  fill="black" fillOpacity="0.54"/>
                        </svg>}
                </SvgIcon>
            )
        }
        return (
            <SvgIcon sx={{
                display: (isChecked || hover) && 'none',
                width: 20,
                height: 20,
                flexShrink: 0,
                marginLeft: '2px',
            }}
            >
                {currentGroup.resource === group.resource ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM10 8C10 9.1 9.1 10 8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8ZM12 13V14H4V13C4 11.67 6.67 11 8 11C9.33 11 12 11.67 12 13Z"
                              fill="#1967D2"/>
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM10 8C10 9.1 9.1 10 8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8ZM12 13V14H4V13C4 11.67 6.67 11 8 11C9.33 11 12 11.67 12 13Z"
                              fill="black" fillOpacity="0.54"/>
                    </svg>}
            </SvgIcon>
        )
    }
    return (
        <SvgIcon sx={{
            display: (isChecked || hover) && 'none',
            width: 20,
            height: 20,
            flexShrink: 0,
            marginLeft: '2px',
        }}
        >
            {currentGroup.resource === group.resource ?
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M15.63 3.84C15.27 3.33 14.67 3 14 3L3 3.01C1.9 3.01 1 3.9 1 5V15C1 16.1 1.9 16.99 3 16.99L14 17C14.67 17 15.27 16.67 15.63 16.16L20 10L15.63 3.84ZM14 15H3V5H14L17.55 10L14 15Z"
                        fill="#1967D2"/>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M15.63 3.84C15.27 3.33 14.67 3 14 3L3 3.01C1.9 3.01 1 3.9 1 5V15C1 16.1 1.9 16.99 3 16.99L14 17C14.67 17 15.27 16.67 15.63 16.16L20 10L15.63 3.84ZM14 15H3V5H14L17.55 10L14 15Z"
                        fill="black" fillOpacity="0.54"/>
                </svg>}
        </SvgIcon>

    )
}

const CreateLabelItem = ({drawerWidth}) => {
    return (
        <ListItemButton
            disableRipple
            onClick={() => store.dispatch(openAddNewLabelDialog(true))}
            sx={{
                maxWidth: drawerWidth,
                borderRadius: '0px 20px 20px 0px',
                height: 40,
            }}
        >
            <ListItemIcon
                sx={{
                    marginRight: -2,
                    marginLeft: '6px',
                }}
            >
                <AddOutlined sx={{width: 20, height: 20}}/>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Box
                        sx={{
                            width: '138px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                            }}
                        >
                            Create label
                        </Typography>
                    </Box>
                }
            />
        </ListItemButton>
    )
}
