const typography = {
    body1: {
        color: '#202124',
        fontFamily: `"Open Sans", "Roboto"`,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
    },
    button: {
        fontSize: 14,
        fontFamily: "Open Sans",
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
    },
    sideBarNumbers: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 14,
        fontFamily: `"Open Sans"`,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    subtitle2: {
        color: '#3C4043',
        fontWeight: 600,
        fontSize: 18,
        fontFamily: `"Open Sans"`,
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '-0.72px',
    },
    subscriptionTitle: {
        color: 'rgba(32, 33, 36, 0.54)',
        fontSize: 12,
        fontFamily: "Open Sans",
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 13,
        fontFamily: `"Roboto"`,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.16px',
    },
    settingsMenu: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 16,
        fontFamily: `"Roboto"`,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: '0.15px',
    },
    dialogTitle: {
        fontSize: 20,
        fontFamily: `"Roboto"`,
        fontWeight: 500,
        fontStyle: 'normal',
        lineHeight: '160%',
        letterSpacing: '0.15px',
    },
    dialogButton: {
        fontSize: 14,
        fontFamily: `"Roboto"`,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.4px',
    },
    contactDialogText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 12,
        fontFamily: `"Open Sans"`,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
    },
};

export default typography;
