import {useEffect} from "react";
import {
    CssBaseline,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

// project import
import ThemeProvider from './theme';
import loginRedirect from "./services/loginRedirect";
import Router from "./routes";
import fetchAllData from "./api/fetchAllData";
import store from "./store/store";
import {openHelpCrunchChat} from "./store/reducers/dialogSlice";
import NotificationBar from "./components/Alerts/NotificationBar";
import sockets from "./api/sockets";

export default function App() {
    const dispatch = useDispatch();
    const needLogin = useSelector((state) => state.user.needLogin);
    useEffect(() => {
        async function fetchData() {
            const result = await fetchAllData(dispatch);
            const getUserResult = result.find(item => item.type === 'user/getUser/fulfilled');
            if (!getUserResult || getUserResult.payload.need_login) return null;
            const socket = sockets(getUserResult.payload.me.socket_token)
            return () => socket.close();
        }

        fetchData();
    }, [dispatch]);
    if (needLogin) {
        loginRedirect();
    }
    window.HelpCrunch('onReady', () => {
        window.HelpCrunch('onChatClose', () => {
            store.dispatch(openHelpCrunchChat(false))
        });
    });

    return (
        <ThemeProvider>
            <CssBaseline/>
            <NotificationBar/>
            <Router/>
        </ThemeProvider>
    );
}
