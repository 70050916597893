import * as React from "react";
import {useSelector} from "react-redux";
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import { isChrome, isMacOs } from 'react-device-detect';

// project import
import ContactsPage from "./pages/ContactsPage";
import ManageSubscriptionPage from "./pages/ManageSubscriptionPage";
import TrialPage from "./pages/TrialPage";
import ManageUsersPage from "./pages/ManageUsersPage";
import ChangeLicenseCountPage from "./pages/ChangeLicenseCountPage";
import NotFoundPage from "./pages/NotFoundPage";
import FallbackError from "./components/FallbackError/FallbackError";
import MainLayout from "./layouts/MainLayout";
import Page404 from './pages/Page404';
import MobilePage from "./components/MobilePage/MobilePage";

export default function Router() {
    const theme = useTheme();
    const subscription = useSelector((state) => state.user.subscription);
    const isTrialOver = (subscription.plan_id === 1 && subscription.status === 0);
    const matches = useMediaQuery('(min-width:993px)'); // 992px - mobile, 993px desktop
    const router = (isChrome && isMacOs) || matches ? mainRoute(isTrialOver) : mobileRoute();
    return <RouterProvider router={router}/>;
}

const mainRoute = (isTrialOver) => {
    const router = createBrowserRouter(
        [
            {
                errorElement: <FallbackError/>,
                children: [
                    {
                        path: "/",
                        element: !isTrialOver ? <ContactsPage/> : <TrialPage type={'trial_over'}/>,
                    },
                    {
                        path: "manage_users",
                        element: <ManageUsersPage/>,
                    },
                    {
                        path: "manage_subscription",
                        element: <MainLayout/>,
                        children: [
                            {
                                element: <ManageSubscriptionPage/>,
                                index: true
                            },
                            {
                                path: "change_subscription",
                                element: <ChangeLicenseCountPage/>,
                            },
                        ]
                    },
                    {path: '404', element: <Page404/>},
                    {path: '*', element: <Navigate to="/404"/>},
                    // {
                    //     path: "trial_over",
                    //     element: <TrialPage type={'trial_over'}/>,
                    // },
                    // {
                    //     path: "trial_checking_eligibility",
                    //     element: <TrialPage type={'checking_eligibility'}/>,
                    // },
                    // {
                    //     path: "trial_congratulations",
                    //     element: <TrialPage type={'congratulations'}/>,
                    // },
                    // {
                    //     path: "trial_sorry",
                    //     element: <TrialPage type={'sorry'}/>,
                    // },
                    // {
                    //     path: "trial_sorry",
                    //     element: <TrialPage type={'sorry'}/>,
                    // },
                ],
            },
        ],
        {
            basename: process.env.REACT_APP_PREFIX,
        }
    );
    return router;
}

const mobileRoute = () => {
    const router = createBrowserRouter(
        [
            {
                errorElement: <FallbackError/>,
                children: [
                    {path: '*', element: <MobilePage/>},
                ],
            },
        ],
        {
            basename: process.env.REACT_APP_PREFIX,
        }
    );
    return router;
}
