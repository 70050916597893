import * as React from 'react';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
    AvatarGroup, SvgIcon
} from "@mui/material";
import {DeleteOutlineOutlined, ModeEditOutlined, ShareOutlined, Group} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";

// project import
import {SkeletonContactsAppBar} from "../Skeletons";
import AddContactsMenu from "./AddContactsMenu";
import store from "../../store/store";
import {
    openAlertDeleteLabelDialog,
    openRenameLabelDialog, openShareLabelsDialog
} from "../../store/reducers/dialogSlice";
import {
    setDeleteCurrentGroup,
    setGroupsToShare,
    setLabelToRename
} from "../../store/reducers/groupsSlice";
import {checkRole} from "../../services/checkRole";
import * as RolesConstants from "../../constants/roles";
import {getSharedUsers} from "../../services/getSharedUsers";

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function ContactsAppBar() {
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const user = useSelector((state) => state.user.user);
    const contacts = useSelector((state) => state.contacts.contacts);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const subscription = useSelector((state) => state.user.subscription);
    const groups = useSelector((state) => state.groups.groups);
    // const domainData = useSelector((state) => state.user.domainData);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const contactsWithEmails = useSelector((state) => state.contacts.contactsWithEmails);
    let usersSharedWith = currentGroup?.share?.length ? [...currentGroup.share.filter(item => item.type === 1), ...currentGroup.domains_groups_share.map(item => ({
        ...item,
        isGroup: true
    }))] : [];
    const shareLabelStatus = useSelector((state) => state.groups.shareLabelStatus);
    const sharingUsers = useSelector((state) => state.groups.sharingUsers);
    const sharingGroups = useSelector((state) => state.groups.sharingGroups);
    const sharingUsersInfo = sharingUsers.map(sharedUser => {
        // if (user.domain_user) {
        //     let userInfo = domainData.users && domainData.users.find(item => sharedUser === item.email);
        //     if (!userInfo) {
        //         userInfo = domainData.groups && domainData.groups.find(item => sharedUser === item.email);
        //     }
        //     return userInfo;
        // }
        return subscription.status !== 2 ? contactsWithEmails.find(item => sharedUser === item.email) : subscription.licensed_users.find(item => sharedUser === item.email);
    });
    if (sharingUsers.length && sharingGroups.find(item => item === currentGroup.resource)) {
        usersSharedWith = [...usersSharedWith, ...sharingUsers.map(email => ({email}))];
    }
    const getPhoto = (user) => {
        if (user.isGroup) {
            user.photo = null;
            return user;
        }
        const contact = shareableUsers.users.find(contact => contact.email === user.email);
        return contact ? contact.photo : null;
    }
    const uniqUsersSharedWith = usersSharedWith.reduce((acc, user) => {
        return acc.some(item => item.email === user.email) ? acc : [...acc, {
            ...user,
            photo: getPhoto(user),
        }];
    }, []);

    if (statusFirstLoad === 'loading') return (
        <>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <SkeletonContactsAppBar DrawerHeader={DrawerHeader}/>
        </>
    );
    const labelName = currentGroup ? currentGroup.resource === 'contactGroups/myContacts' ? 'All Contacts' : currentGroup.name : '';
    const handleClickOpenRenameLabelDialog = () => {
        store.dispatch(setLabelToRename(
            {
                resource: currentGroup.resource,
                name: groups.find(group => group.resource === currentGroup.resource).name
            }
        ));
        store.dispatch(openRenameLabelDialog(true));
    };
    const handleClickOpenAlertDeleteLabelDialog = () => {
        store.dispatch(setDeleteCurrentGroup(true));
        store.dispatch(openAlertDeleteLabelDialog(true));
    };
    const handleShareLabel = () => {
        store.dispatch(setGroupsToShare({groups: [{group: currentGroup.resource}]}));
        store.dispatch(openShareLabelsDialog(true));
    };
    let sharedUsers = getSharedUsers(currentGroup, user, contacts, shareableUsers);
    sharedUsers = sharedUsers.filter(sharedUser => sharedUser.email !== user.email);
    return (
        <>
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Toolbar>
                <Typography
                    variant={'subtitle2'}
                    noWrap
                    sx={{
                        ml: -1.2,
                        width: 'auto',
                        maxWidth: '200px',
                        paddingRight: '8px',
                    }}
                >
                    {labelName}
                </Typography>
                {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <AddContactsMenu buttonType={'icon'}/>}
                {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <Tooltip title='Rename label' placement="bottom">
                    <span>
                        <IconButton
                            onClick={handleClickOpenRenameLabelDialog}
                            disabled={currentGroup?.resource === 'contactGroups/myContacts'}
                        >
                            <ModeEditOutlined/>
                        </IconButton>
                    </span>
                </Tooltip>}
                {checkRole(RolesConstants.ROLE_DELETE, currentGroup) && <Tooltip
                    title='Your contacts will not be deleted. They will be available in All Contacts.  If you share this label with users, the label and its contacts will be deleted on their end.'
                    placement="bottom">
                    <span>
                        <IconButton
                            onClick={handleClickOpenAlertDeleteLabelDialog}
                            disabled={currentGroup?.resource === 'contactGroups/myContacts'}
                        >
                            <DeleteOutlineOutlined/>
                        </IconButton>
                    </span>
                </Tooltip>}
                <Box sx={{flexGrow: 1}}/>
                <Box sx={{display: 'flex'}}>
                    <SharedUsersAvatar sharedUsers={sharedUsers}/>
                    <Box ml={'20px'}>
                        <Button variant="contained" onClick={handleShareLabel}
                                sx={{background: "rgba(59, 125, 237, 1)"}}
                                disabled={!checkRole(RolesConstants.ROLE_SHARE, currentGroup)}
                                startIcon={
                                    <SvgIcon sx={{width: 15, height: 24}}>
                                        <svg width="15" height="24" viewBox="0 0 15 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 6.37012L1 11.403V14.5002L11 9.46731V6.37012Z" fill="white"/>
                                            <path d="M11 6.37149L3.30769 2.5V5.59719L11 9.46867V6.37149Z" fill="white"/>
                                            <path d="M4 17.6288L11.6923 21.5V18.4031L4 14.5319V17.6288Z" fill="white"/>
                                            <path d="M4 17.6299L14 12.5974V9.50042L4 14.5329V17.6299Z" fill="white"/>
                                        </svg>
                                    </SvgIcon>
                                }
                        >
                            <Typography variant={'button'}
                                        sx={{
                                            textTransform: "none",
                                            color: !checkRole(RolesConstants.ROLE_SHARE, currentGroup) ? 'rgba(0, 0, 0, 0.32)' : '#FFF',
                                        }}
                            >Share label</Typography>
                        </Button>
                    </Box>
                </Box>
            </Toolbar>
        </>
    )
}

const SharedUsersAvatar = ({sharedUsers}) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    return (
        <AvatarGroup max={5}
                     key={'SharedUsersAvatar'}
                     spacing={'medium'}
                     renderSurplus={(surplus) => <span>+{surplus}</span>}
                     total={sharedUsers.length < 100 ? sharedUsers.length : 99 + 4}
                     sx={{
                         '& .MuiAvatar-root': {
                             width: 32,
                             height: 32,
                             color: 'rgba(0, 0, 0, 0.87)',
                             fontSize: 13,
                             fontFamily: `"Open Sans"`,
                             fontStyle: 'normal',
                             fontWeight: 700,
                             lineHeight: '16px',
                         },
                     }}
        >
            {
                sharedUsers.map((sharedUser => {
                    let groupSyncStatus = '';
                    if (sharedUser.shareType === 'GROUP') {
                        const usersInDomainGroup = currentGroup.share.filter(item => item.domain_group_email === sharedUser.email);
                        const usersInDomainGroupCount = usersInDomainGroup.length || sharedUser.count || 0;
                        const usersWithStatusOK = usersInDomainGroup.filter(user => user.sync_status === 'OK');
                        groupSyncStatus = usersInDomainGroupCount === usersWithStatusOK.length ? 'OK' : `ON_QUEUE`;
                    }
                    return (
                        <Tooltip
                            placement="top"
                            title={sharedUser.name}
                            key={sharedUser.email}
                        >
                            <Badge
                                key={sharedUser.email}
                                color={getSyncStatus(sharedUser.shareType !== 'GROUP' ? sharedUser.sync_status : groupSyncStatus)}
                                variant={'dot'}
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                {sharedUser.shareType !== 'GROUP' ?
                                    <Avatar alt={sharedUser.name}
                                            sx={{width: 32, height: 32}}
                                            src={sharedUser.photo}/> :
                                    <Avatar alt={sharedUser.name} sx={{width: 32, height: 32}}>
                                        <Group/>
                                    </Avatar>}
                            </Badge>
                        </Tooltip>
                    )
                }))
                }
                </AvatarGroup>
                )
            }

                const getSyncStatus = (status) => {
                let result = '';
                switch (status) {
                case 'ON_QUEUE':
                result = 'warning';
                break;
                case 'OK':
                result = 'success';
                break;
                case 'WAITING_LABEL':
                result = 'error';
                break;
                case 'WAITING_CONTACTS':
                result = 'warning';
                break;
                default:
                result = 'grey';
            }
                return result;
            }
