import {Alert, Button, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {MonetizationOnOutlined} from "@mui/icons-material";
import * as React from "react";
import {useNavigate} from "react-router-dom";

// project import

export default function SubscriptionStatusAlert() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const userData = user.user;
    const subscription = useSelector((state) => state.user.subscription);
    let message = '';
    let minWidth = 550;
    if (subscription.plan_id !== 1) {
        if (subscription.status === 0) {
            message = 'Your subscription has expired';
        } else {
            return null;
        }
    } else if (subscription.status === 0) {
        if (!userData.domain_admin && userData.domain_user) {
            message = 'The free trial has expired. Please contact the domain admin to buy a subscription';
            minWidth = 650;
        } else {
            return null;
        }
    } else {
        message = <>Free trial version expires in <Typography
            component={'span'}
            sx={{
                color: 'rgba(95, 43, 1, 1)',
                fontSize: 16,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 600,
                letterSpacing: '0.17px',
                fontFeatureSettings: "'clig' off, 'liga' off",
            }}
        >{subscription.remaining_days} {subscription.remaining_days === 1 ? 'day' : 'days'}</Typography></>;
    }
    return (
        <Alert
            sx={{
                minWidth,
                maxWidth: 900,
                borderRadius: '8px',
                background: '#FDF0E6'
            }}
            severity="error"
            color="warning"
            action={
                <RenderButton navigate={navigate}/>
            }
        >
            <Typography
                sx={{
                    color: 'rgba(95, 43, 1, 1)',
                    fontSize: 16,
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    letterSpacing: '0.17px',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                }}
            >{message}</Typography>
        </Alert>
    )
}

const RenderButton = ({navigate}) => {
    return (
        <Button color="inherit" startIcon={<MonetizationOnOutlined/>}
                sx={{width: 214, height: '30px', marginTop: '-1px'}}
                onClick={() => navigate("/manage_subscription")}
        >
            <Typography
                sx={{
                    color: 'rgba(95, 43, 1, 1)',
                    fontSize: 13,
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                }}
            >
                manage subscription
            </Typography>
        </Button>
    )
}
