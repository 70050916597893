import * as React from "react";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Fragment} from "react";
import {IconButton, Menu, MenuItem, Tooltip, Typography, Stack, SvgIcon} from "@mui/material";
import {FilterList as FilterListIcon, SortOutlined, SortByAlphaOutlined} from "@mui/icons-material";
import {useSelector} from "react-redux";

// project import
import store from "../../store/store";
import {setLabelsFilter} from "../../store/reducers/groupsSlice";

export default function LabelsPanelFilter() {
    const labelsFilter = useSelector((state) => state.groups.labelsFilter);
    const handleClickFilter = (popupState, filter) => {
        popupState.close();
        store.dispatch(setLabelsFilter({...labelsFilter, ...filter}));
    };
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <Fragment key="settings_menu1">
                    <Tooltip title='Filter' placement="bottom">
                        <IconButton
                            edge='end'
                            aria-label='settings'
                            color='default'
                            size="medium"
                            sx={{
                                marginRight: '-5px'
                            }}
                            {...bindTrigger(popupState)}
                        >
                            <FilterListIcon
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem
                            disableRipple
                            sx={[{
                                '&:hover': {backgroundColor: 'transparent'},
                                cursor: 'default',
                            }]}
                        >
                            <Typography variant={'button'} sx={{textTransform: "none", color: 'rgba(95, 99, 104, 1)'}}>
                                Sort by
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.sortBy === 'last_modified' && labelsFilter?.order === 'DESC' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {sortBy: 'last_modified', order: 'DESC'});
                            }}>
                            <Stack spacing={1} direction={'row'}>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7 5V15H10L6 19L2 15H5V5H7ZM16 18H12V16H16V18ZM12 8V6H22V8H12ZM19 13H12V11H19V13Z" fill={labelsFilter?.sortBy === 'last_modified' && labelsFilter?.order === 'DESC' ? 'rgba(25, 103, 210, 1)' : 'rgba(0, 0, 0, 0.54)'}/>
                                    </svg>
                                </SvgIcon>
                                <Typography
                                    variant={'settingsMenu'}
                                    sx={{
                                        color: labelsFilter?.sortBy === 'last_modified' && labelsFilter?.order === 'DESC' && 'rgba(25, 103, 210, 1)'
                                    }}
                                >
                                    Date modified (newest first)
                                </Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.sortBy === 'last_modified' && labelsFilter?.order === 'ASC' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {sortBy: 'last_modified', order: 'ASC'});
                            }}>
                            <Stack spacing={1} direction={'row'}>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7 5V15H10L6 19L2 15H5V5H7ZM16 6H12V8H16V6ZM12 16V18H22V16H12ZM19 11H12V13H19V11Z" fill={labelsFilter?.sortBy === 'last_modified' && labelsFilter?.order === 'ASC' ? 'rgba(25, 103, 210, 1)' : 'rgba(0, 0, 0, 0.54)'}/>
                                    </svg>
                                </SvgIcon>
                                <Typography
                                    variant={'settingsMenu'}
                                    sx={{
                                        color: labelsFilter?.sortBy === 'last_modified' && labelsFilter?.order === 'ASC' && 'rgba(25, 103, 210, 1)'
                                    }}
                                >
                                    Date modified (oldest first)
                                </Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.sortBy === 'alphabetical' && labelsFilter?.order === 'ASC' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {sortBy: 'alphabetical', order: 'ASC'});
                            }}>
                            <Stack spacing={1} direction={'row'}>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.5 6.26953L0 17.7295H1.84L2.76 15.2795H7.87L8.79 17.7295H10.63L6.14 6.26953H4.5ZM3.37 13.6395L5.31 8.45953L7.25 13.6395H3.37ZM16.7301 16.1396H22.8501V17.7296H14.3201V16.4396L20.2401 7.87965H14.3601V6.27965H22.6601V7.53965L16.7301 16.1396Z" fill={labelsFilter?.sortBy === 'alphabetical' && labelsFilter?.order === 'ASC' ? 'rgba(25, 103, 210, 1)' : 'rgba(0, 0, 0, 0.54)'}/>
                                        <path d="M11 15L14 12L11 9V15Z" fill="black" fillOpacity="0.54"/>
                                    </svg>
                                </SvgIcon>
                                <Typography
                                    variant={'settingsMenu'}
                                    sx={{
                                        color: labelsFilter?.sortBy === 'alphabetical' && labelsFilter?.order === 'ASC' && 'rgba(25, 103, 210, 1)'
                                    }}
                                >
                                    Alphabetical (A to Z)
                                </Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.sortBy === 'alphabetical' && labelsFilter?.order === 'DESC' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {sortBy: 'alphabetical', order: 'DESC'});
                            }}>
                            <Stack spacing={1} direction={'row'}>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2852_22015)">
                                            <path d="M18 6.26953L13.5 17.7295H15.34L16.26 15.2795H21.37L22.29 17.7295H24.13L19.64 6.26953H18ZM16.87 13.6395L18.81 8.45953L20.75 13.6395H16.87ZM2.73031 16.1396H8.85031V17.7296H0.320312V16.4396L6.24031 7.87957H0.360312V6.27957H8.66031V7.53957L2.73031 16.1396Z" fill={labelsFilter?.sortBy === 'alphabetical' && labelsFilter?.order === 'DESC' ? 'rgba(25, 103, 210, 1)' : 'rgba(0, 0, 0, 0.54)'}/>
                                            <path d="M10.5 15L13.5 12L10.5 9V15Z" fill="black" fillOpacity="0.54"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2852_22015">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </SvgIcon>
                                <Typography
                                    variant={'settingsMenu'}
                                    sx={{
                                        color: labelsFilter?.sortBy === 'alphabetical' && labelsFilter?.order === 'DESC' && 'rgba(25, 103, 210, 1)'
                                    }}
                                >
                                    Alphabetical (Z to A)
                                </Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem
                            disableRipple
                            sx={[{
                                '&:hover': {backgroundColor: 'transparent'},
                                cursor: 'default',
                            }]}
                        >
                            <Typography variant={'button'} sx={{textTransform: "none", color: 'rgba(95, 99, 104, 1)'}}>
                                Display
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{background: (labelsFilter?.filter === 'all' || labelsFilter?.filter === null) && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {filter: 'all'});
                            }}>
                            <Typography
                                variant={'settingsMenu'}
                                sx={{
                                    color: (labelsFilter?.filter === 'all' || labelsFilter?.filter === null)
                                        && 'rgba(25, 103, 210, 1)'
                                }}
                            >
                                All labels
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.filter === 'shared' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {filter: 'shared'});
                            }}>
                            <Typography
                                variant={'settingsMenu'}
                                sx={{color: labelsFilter?.filter === 'shared' && 'rgba(25, 103, 210, 1)'}}
                            >
                                Shared
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.filter === 'not_shared' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {filter: 'not_shared'});
                            }}>
                            <Typography
                                variant={'settingsMenu'}
                                sx={{color: labelsFilter?.filter === 'not_shared' && 'rgba(25, 103, 210, 1)'}}
                            >
                                Not shared
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{background: labelsFilter?.filter === 'shared_with_me' && '#E8F0FE'}}
                            onClick={() => {
                                handleClickFilter(popupState, {filter: 'shared_with_me'});
                            }}>
                            <Typography
                                variant={'settingsMenu'}
                                sx={{color: labelsFilter?.filter === 'shared_with_me' && 'rgba(25, 103, 210, 1)'}}
                            >
                                Shared with me
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Fragment>
            )}
        </PopupState>
    );
}
