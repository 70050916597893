import {
    getSubscriptionData,
    updateShareableUsers,
    updateUser,
    getAmount, setPricePerUser, setCounter, setSubscriptionType, setCurrency,
    getUser, getDomainInformation,
} from "../store/reducers/userSlice";
import {getContactsAndLabels, updateStatusFirstLoad} from "../store/reducers/contactsSlice";
import {setDomainData, updateGroups} from "../store/reducers/groupsSlice";
import {setGA4ClientId} from "../store/reducers/dataSlice";
import {usersCountToPlanCount} from "../services/usersCountToPlanCount";

export default async function fetchAllData(dispatch) {
    try {
        // dispatch(getContactsAndLabels()).then(
        //     result => {
        //         if (result.type === 'contacts/getContactsAndLabels/fulfilled') {
        //             dispatch(updateGroups({groups: result.payload.groups}));
        //             let shareableContacts = result.payload.contacts.filter(
        //                 elem =>
        //                     elem.email && elem.email.toLowerCase() !== getUserResult.payload.me.email.toLowerCase()
        //             )
        //             shareableContacts = shareableContacts.map(item => {
        //                 if (item.email) {
        //                     return {
        //                         email: item.email,
        //                         name: item.name,
        //                         photo: item.photo,
        //                         group: false,
        //                     };
        //                 }
        //                 return null;
        //             })
        //             dispatch(updateShareableUsers(shareableContacts));
        //         }
        //     },
        //     error => console.log(error)
        // );
        const result = await Promise.all([
            dispatch(getUser()),
            dispatch(getSubscriptionData()),
            dispatch(getContactsAndLabels()),
            dispatch(getDomainInformation()),
        ]);
        const getUserResult = result.find(item => item.type === 'user/getUser/fulfilled');
        if (getUserResult && !getUserResult.payload.need_login) {
            const user = getUserResult.payload.me;
            let subscription = result.find(item => item.type === 'groups/getSubscriptionData/fulfilled');
            if (subscription) {
                subscription = subscription.payload;
                dispatch(setSubscriptionType(subscription.status !== 0 ? subscription.plan_id === 2 ? 'monthly' : 'annually' : 'annually'));
                dispatch(setCurrency(subscription.status !== 0 ? subscription.plan_id !== 1 ? subscription.subscription.currency : 'USD' : 'USD'));
                const SUBSCRIPTION_OVER = subscription.plan_id !== 1 && subscription.status === 0;
                const TRIAL = subscription.plan_id === 1;
                let counter = (!TRIAL && subscription.status === 1) ? subscription.quantity : (subscription.status !== 0 ? subscription.connected_users.length : 5);
                counter = Math.max(counter, 2);
                if (subscription.subscription.version === 2) {
                    const result = usersCountToPlanCount(counter);
                    counter = result.to;
                }
                if (subscription.plan_id === 1 && counter < 5) {
                    counter = 5;
                }
                dispatch(setCounter(counter));
                const amount = await dispatch(getAmount({
                    period: subscription.status !== 0 ? subscription.plan_id === 2 ? 'MONTHLY' : 'YEAR' : 'YEAR',
                    quantity: counter,
                    version: SUBSCRIPTION_OVER || TRIAL ? 3 : subscription.subscription.version,
                    currency: subscription.subscription.currency,
                }));
                dispatch(setPricePerUser(counter ? Math.trunc((amount.payload.price / counter) * 100) / 100 : 0));
            }
            const groupsAndContacts = result.find(item => item.type === 'contacts/getContactsAndLabels/fulfilled');
            if (groupsAndContacts) {
                dispatch(updateGroups({groups: groupsAndContacts.payload.groups, user}));
                let shareableContacts = groupsAndContacts.payload.contacts.filter(
                    elem =>
                        elem.email && elem.email.toLowerCase() !== getUserResult.payload.me.email.toLowerCase()
                )
                shareableContacts = shareableContacts.map(item => {
                    if (item.email) {
                        return {
                            email: item.email,
                            name: item.name,
                            photo: item.photo,
                            group: false,
                        };
                    }
                    return null;
                })
                await dispatch(updateShareableUsers(shareableContacts));
            }
            const domainInformation = result.find(item => item.type === 'user/getDomainInformation/fulfilled');
            if (domainInformation) {
                const shareableDomainUsers = domainInformation.payload.users.filter(
                    elem => elem.email && elem.email.toLowerCase() !== getUserResult.payload.me.email.toLowerCase()
                )
                const users = shareableDomainUsers.map(el => {
                    if (el.email) {
                        return {
                            email: el.email,
                            name: el.name.fullName,
                            photo: el.photo,
                            group: false,
                            domainUser: true
                        }
                    }
                    return null
                })
                await dispatch(updateShareableUsers(users));
                await dispatch(setDomainData(domainInformation.payload));
                const domainsGroups = domainInformation.payload.groups;
                if (domainsGroups && domainsGroups.length) {
                    const users = domainsGroups.map(el => {
                        if (el.email) {
                            return {email: el.email, name: el.name, photo: null, group: true}
                        }
                        return null
                    })
                    await dispatch(updateShareableUsers(users));
                }
            }
            await dispatch(updateStatusFirstLoad({statusFirstLoad: 'succeeded'}));

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                scm_is_domain_user: user.domain_user,
            });
            const isAdmin = subscription.status === 1 ? 1 : 0;
            window.dataLayer.push({scm_is_admin_user: isAdmin})
            window.dataLayer.push({scm_email: user.email})
            window.dataLayer.push({userId: user.user_id})
            window.dataLayer.push({
                event: 'userSet'
            })
            if (window.clarity) {
                window.clarity("identify", user.email)
            }
            const setYM = () => {
                if (window.ym) {
                    // console.log('window.ym')
                    window.ym(84600490, 'userParams', {
                        email: user.email
                    })
                } else {
                    setTimeout(() => {
                        setYM()
                    }, 2000)
                }
            }
            setYM();
            if (window.mixpanel) {
                window.mixpanel.identify(user.email);
                window.mixpanel.people.set({
                    $email: user.email, // only reserved properties need the $
                    USER_ID: user.email // use human-readable names
                });
                window.mixpanel.track('LOADED', {
                    admin: isAdmin
                })
            }
            // if (window.amplitude) {
            //     // console.log('AMPLITUDE')
            //     window.amplitude.getInstance().setUserId(user.email)
            //     window.amplitude.getInstance().logEvent('LOADED')
            // }

            if (window.LiveChatWidget) {
                // console.log('LiveChatWidget')
                window.LiveChatWidget.call(
                    'set_customer_email',
                    user.email
                )
            }
            window.HelpCrunch('onReady', () => {
                const data = {
                    email: user.email,
                };
                window.HelpCrunch('updateUser', data);
            });
            const isPaid = subscription.plan_id === 1 ? 0 : 1;
            window.dataLayer.push({scm_paid: isPaid});
            if (getUserResult.payload.domain_is_first_time) {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: 'DOMAIN_FIRST_TIME_LOGIN'
                })
                window.dataLayer.push({
                    is_first_domain_enter: 1
                })
            }
            window.dataLayer.push({
                event: 'optimize.activate',
            });
            window.bugsnagUserEmail = user.email;
            window.bugsnagUserId = user.user_id;
            try {
                if (window.ga) {
                    const gaAll = window.ga.getAll();
                    if (gaAll) {
                        const clientId = gaAll[0].get('clientId');
                        dispatch(setGA4ClientId({clientId}));
                    }
                }
            } catch (error) {
                console.log("GA4 error")
            }
        }

        return result;
    } catch (error) {
        throw Error(error);
    }
};
