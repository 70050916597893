import * as React from "react";
import {useSelector} from "react-redux";
import {useState} from "react";
import {
    List,
    ListItemButton,
    ListItemIcon, ListItemSecondaryAction,
    ListItemText, SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import {PeopleAltOutlined, ShareOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {setRowSelectionModel} from "../../store/reducers/contactsSlice";
import {changeContacts, setGroupsToShare} from "../../store/reducers/groupsSlice";
import {openShareLabelsDialog, openWarningShareAllContactsDialog} from "../../store/reducers/dialogSlice";

export function AllContactsLabel() {
    const groups = useSelector((state) => state.groups.groups);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const contacts = useSelector((state) => state.contacts.contacts);
    const countAllContacts = contacts.length;
    const handleClickAllContacts = () => {
        store.dispatch(changeContacts({resource: 'contactGroups/myContacts'}));
        store.dispatch(setRowSelectionModel([]));
    };
    const handleShareAllContacts = (event) => {
        event.stopPropagation();
        const myContacts = groups.find(group => group.resource === 'contactGroups/myContacts');
        store.dispatch(setGroupsToShare({groups: [{group: 'contactGroups/myContacts'}]}));
        if (myContacts && myContacts.share && myContacts.share.length) {
            store.dispatch(openShareLabelsDialog(true));
            return;
        }
        store.dispatch(openWarningShareAllContactsDialog(true));
    };
    const [hover, setHover] = useState(false);
    const myContactsGroup = groups.find(group => group.resource === 'contactGroups/myContacts');
    const AllContactsIcon = () => {
        if (myContactsGroup?.share.length > 0) {
            return (
                <SvgIcon sx={{
                    width: 20,
                    height: 20,
                    flexShrink: 0,
                    marginLeft: '8px',
                }}
                >
                    {hover && currentGroup?.resource === 'contactGroups/myContacts' ?
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM4 14C4.56 11.33 6.11 8.67 10 8.13V6L14 9.73L10 13.47V11.28C7.22 11.28 5.39 12.13 4 14Z"
                                  fill="#1967D2"/>
                        </svg> :
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM4 14C4.56 11.33 6.11 8.67 10 8.13V6L14 9.73L10 13.47V11.28C7.22 11.28 5.39 12.13 4 14Z"
                                  fill="black" fillOpacity="0.54"/>
                        </svg>}
                </SvgIcon>
            )
        }
        return <PeopleAltOutlined
            sx={{
                color: hover && currentGroup && currentGroup.resource === 'contactGroups/myContacts' && 'rgba(25, 103, 210, 1)',
                width: 24,
                height: 24,
                flexShrink: 0,
                marginLeft: '6px',
            }}
        />
    }
    return (
        <List
            disablePadding
            sx={{
                marginTop: '22px',
                marginBottom: '8px',
                '& .MuiListItemButton-root:hover': {
                    borderRadius: '0px 20px 20px 0px',
                },
            }}>
            <ListItemButton
                key={'all_contacts'}
                onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
                disableRipple
                onClick={handleClickAllContacts}
                sx={{
                    bgcolor: currentGroup && currentGroup.resource === 'contactGroups/myContacts' && '#E8F0FE',
                    borderRadius: '0px 20px 20px 0px',
                    height: 40,
                }}
            >
                <ListItemIcon>
                    <AllContactsIcon/>
                </ListItemIcon>
                <ListItemText
                    sx={{
                        marginLeft: '-10px',
                    }}
                    primary={
                        <Typography
                            sx={{
                                color: hover && currentGroup && currentGroup.resource === 'contactGroups/myContacts' && 'rgba(25, 103, 210, 1)',
                            }}
                        >
                            All Contacts
                        </Typography>
                    }
                />
                <ListItemSecondaryAction
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant={'sideBarNumbers'}
                        edge="end"
                        sx={{
                            display: hover && 'none',
                            marginRight: '9px',
                        }}>{countAllContacts}</Typography>
                    <Tooltip title='Share all contacts' placement="bottom">
                        <ShareOutlined
                            edge="end"
                            aria-label="share-label"
                            onClick={handleShareAllContacts}
                            sx={{
                                display: !hover && 'none',
                                width: 18,
                                height: 18,
                                flexShrink: 0,
                                fill: currentGroup && currentGroup.resource !== 'contactGroups/myContacts' && 'rgba(0, 0, 0, 0.32)' || currentGroup && currentGroup.resource === 'contactGroups/myContacts' && '#3B7DED',
                                '&:hover': {
                                    fill: currentGroup && currentGroup.resource !== 'contactGroups/myContacts' && 'rgba(0, 0, 0, 0.6)' || currentGroup && currentGroup.resource === 'contactGroups/myContacts' && '#1659B6',
                                },
                                marginRight: '7px',
                            }}
                        />
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItemButton>
        </List>
    )
}
