import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from "react-redux";
import {Grid, IconButton, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openAlertDeleteContactDialog} from "../../store/reducers/dialogSlice";
import {deleteContacts, getContactsAndLabels, setContactsToDelete} from "../../store/reducers/contactsSlice";
import {updateGroups} from "../../store/reducers/groupsSlice";

export default function AlertDeleteContactDialog() {
    const open = useSelector((state) => state.dialogsOpen.alertDeleteContact.value);
    const contactsToDelete = useSelector((state) => state.contacts.contactsToDelete);
    const contacts = useSelector((state) => state.contacts.contacts);
    const contactToDelete = contacts.find(contact => contact.id === contactsToDelete[0]);
    const statusContacts = useSelector((state) => state.contacts.status);
    const loading =     statusContacts === 'loading';
    const handleClose = async () => {
        store.dispatch(setContactsToDelete([]));
        store.dispatch(openAlertDeleteContactDialog(false));
        const groupsAndContacts = await store.dispatch(getContactsAndLabels());
        if (groupsAndContacts.error) {
            console.log('Error getContactsAndLabels')
            console.error(groupsAndContacts)
        }
        if (groupsAndContacts && !groupsAndContacts.error) {
            store.dispatch(updateGroups(groupsAndContacts.payload));
        }
    };
    const handleDeleteContact = async () => {
        await store.dispatch(deleteContacts(contactsToDelete));
        await handleClose();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-contact-dialog-title"
            aria-describedby="alert-delete-contact-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Delete contact{contactsToDelete.length > 1 && <span>s</span>}</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Typography
                    noWrap
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                        marginBottom: 1,
                    }}
                >
                    Are you sure you want to delete {contactsToDelete.length === 1 ? `"${contactToDelete ? contactToDelete.name : ''}" contact` : 'contacts'}?
                </Typography>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <LoadingButton loading={loading} onClick={handleDeleteContact}>
                    <Typography variant={'dialogButton'}
                                sx={{
                                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                                }}
                    >Delete</Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
